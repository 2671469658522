<template>
  <v-dialog v-model="showDialog" fullscreen data-testid="edit-patient-id">
    <template #activator="{ on }">
      <v-btn
        rounded
        color="secondary"
        @click="openDialog"
        class="elevation-1"
        v-on="on"
      >
        Reset PIN
      </v-btn>
    </template>

    <v-card color="#F4F8FE">
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="reset-pin-code-card-title"
      >
        Reset PIN code on patient app
      </BoxedToolbar>

      <BoxedCard>
        <SettingsHeader
          :patient-no="patientNo"
          title="Reset PIN code on patient app"
          :show-icon="true"
        >
          <template #icon>
            <ImgDialpad width="104" height="104" />
          </template>
          <template #text>
            <v-row>
              <v-col class="col-6 spacing-2" style="font-size: 14px">
                <strong class="d-block">What to do in this dashboard</strong>
                Click Reset PIN code and confirm the<br />
                reset on the next screen.</v-col
              >
              <v-col class="col-6 spacing-2" style="font-size: 14px">
                <strong class="d-block"
                  >What happens in the patient’s app?</strong
                >
                The patient can create and confirm a<br />
                new PIN code in the patient app.
              </v-col>
            </v-row>
          </template>
        </SettingsHeader>

        <v-form v-model="formValid" ref="editForm">
          <v-row class="no-gutters mt-8 pr-4">
            <v-col class="text-right">
              <v-btn @click="closeDialog" text class="btn-cancel-reset-pin mr-4"
                ><v-icon>mdi-chevron-left</v-icon> Cancel & return to
                settings</v-btn
              >
              <v-btn
                @click="showConfirmDialog = true"
                :disabled="!formValid"
                :loading="isLoading"
                color="secondary"
                rounded
                class="elevation-1"
                >Reset PIN code</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="apiError">
            <v-col class="offset-4 col-8">
              <v-alert type="error" dense text class="mb-0">{{
                apiError
              }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </BoxedCard>
      <v-dialog v-model="showConfirmDialog" max-width="479" persistent>
        <DialogCard @closeDialog="showConfirmDialog = false">
          <template #title> Confirm the following change </template>
          <template #actions>
            <v-btn
              rounded
              depressed
              @click="showConfirmDialog = false"
              class="mr-4 elevation-1"
              >Cancel</v-btn
            >
            <v-btn
              color="secondary"
              rounded
              depressed
              @click="executeRequest"
              :loading="isLoading"
              class="elevation-1"
              >Confirm</v-btn
            >
          </template>
          <p>
            <span class="d-block">App settings</span>
            <strong>Reset PIN code</strong>
          </p>
        </DialogCard>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import SettingsHeader from '@/components/patient/settings/_SettingsHeader'
import loadingState from '@/constants/loadingState'
import service from '@/services/app-service'
import ImgDialpad from '@/components/images/ImgDialpad'
import DialogCard from '@/components/DialogCard'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import BoxedCard from '@/components/patient/settings/BoxedCard'

const initialState = () => ({
  state: loadingState.INITIAL,
  apiError: null,
})

export default {
  name: 'ResetPinCodeSteps',
  components: {
    BoxedCard,
    BoxedToolbar,
    DialogCard,
    ImgDialpad,
    SettingsHeader,
  },
  props: {
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      deactivateReasonOptions: [
        { value: 'Lost phone', text: 'Lost phone' },
        { value: 'App not working', text: 'App not working' },
      ],
      deactivateReasonRules: [v => !!v || 'A reason is required'],
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    ...notificationMapActions(),
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.state = loadingState.LOADING

      service
        .resetPinCode(this.patientNo)
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('pinCodeChanged', true)
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Reset pincode failed.',
            message:
              error.response?.data?.domainError?.message ||
              'Pincode could not be reset.',
            timeToLive: 10000,
          })

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.showConfirmDialog = false
          this.closeDialog()
        })
    },
  },
}
</script>
