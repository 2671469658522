<template>
  <v-card class="pt-2" :loading="loading">
    <v-card-title class="mb-5 dialog-title spacing-2" data-testid="card-title">
      <slot name="title" />
    </v-card-title>

    <v-btn
      v-if="isCloseEnabled"
      icon
      text
      @click="emitClose"
      class="close-btn-absolut"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-card-text class="pb-3 card-text">
      <slot />

      <v-row>
        <v-col class="text-right">
          <slot name="actions" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DialogCard',
  props: {
    loading: { type: Boolean, required: false, default: false },
    isCloseEnabled: { type: Boolean, required: false, default: true },
  },
  methods: {
    emitClose() {
      this.$emit('closeDialog', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.close-btn-absolut {
  position: absolute;
  top: 12px;
  right: 12px;
}
.card-text {
  color: $nn-black;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.dialog-title {
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
</style>
